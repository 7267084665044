<template>
  <div class="bodywhite">
    <div class="header-light">
      <div class="container">
        <div class="row">
          <button class="col-2" @click="therapieplandetailzutherapieplan()">
            <img src="../assets/icons/arrow_left_black.svg">
          </button>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-mainblue">Medikament im Detail</p>
          </div>
          <div class="col-2 col-right">
          </div>
        </div>
      </div>
    </div>


    <div class="paddingtop115">
      <list-element title="Handelsname" :fill="medInfos.name"></list-element>
      <list-element title="Stärke" :fill="medInfos.dosis"></list-element>
      <list-element title="Regelmäßigkeit" :fill="getRhytmus()"></list-element>
      <list-element title="Einnahmezeiten" :fill="getEinnahmeZeiten()"></list-element>
    </div>
  </div>
</template>


<script>
  import TherapieplanDetailListelementVue from "../components/TherapieplanDetailListelement.vue";
  import router from '../router';
  import { mapGetters } from 'vuex';

  export default {
    name: 'therapieplandetail',
    components: {
      'list-element' :TherapieplanDetailListelementVue,
    },
    data() {
      return {
        medInfos: {},
      };
    },
    methods: {
      therapieplandetailzutherapieplan() {
        router.push({path: '/therapieplan'})
      }, 
      getEinnahmeZeiten(){
        //for(let i = 0; i< this.medInfos.einnahmezeiten.length; i++){
        let ez =  this.medInfos.einnahmezeiten;

        const einnahmeUhrzeiten = this.getEinnahmezeiten;

        let text = "";


        if(parseInt(ez.frueh.zahl) > 0){
          text += einnahmeUhrzeiten.frueh + " Uhr";
        }
        if(parseInt(ez.mittag.zahl) > 0){
          text +=  " " + einnahmeUhrzeiten.mittag + " Uhr";
        }
        if(parseInt(ez.abend.zahl) > 0){
          text +=  " " + einnahmeUhrzeiten.abend + " Uhr";
        }
        if(parseInt(ez.nacht.zahl) > 0){
          text +=  " " + einnahmeUhrzeiten.nacht + " Uhr";
        }
        return text;
      },
      getRhytmus(){
        let rh =  this.medInfos.rhythmus;
        let text = "";
        if(parseInt(rh.montag) && parseInt(rh.montag) && parseInt(rh.montag) && parseInt(rh.dienstag) && parseInt(rh.mittwoch) && parseInt(rh.donnerstag) && parseInt(rh.freitag) && parseInt(rh.samstag) && parseInt(rh.sonntag)){
          text = "täglich";
        } else {
          if(parseInt(rh.montag) > 0){
            text += "Mo"; 
          }
          if(parseInt(rh.dienstag) > 0){
            text += ", Di"; 
          }
          if(parseInt(rh.mittwoch) > 0){
            text += ", Mi"; 
          }
          if(parseInt(rh.donnerstag) > 0){
            text += ", Do"; 
          }
          if(parseInt(rh.freitag) > 0){
            text += ", Fr"; 
          }
          if(parseInt(rh.samstag) > 0){
            text += ", Sa"; 
          }
          if(parseInt(rh.sonntag) > 0){
            text += ", So"; 
          }
        }

        return text;
      }
    },
    computed: {
      ...mapGetters(["getMedikationinfosNo", "getEinnahmezeiten"])
      },
       created() {
      //console.log("TherapieplanDetail:");
      //const route = useRoute();
      //console.log(route.params.id);
      //console.log(this.$route.params.id);
      if(this.$route.params.id){
        this.medInfos = this.getMedikationinfosNo(this.$route.params.id);
        console.log(this.medInfos);
      }

    },
  };
</script>


<style>
</style>