<template>
  <div>
    <div class="marginbottom25 margintop20">
      <div class="container">
        <div class="row">
          <div class="col-8 center">
            <p class="psemibold fontcolor-mainblue">{{ title }}</p>
          </div>
          <div class="col-4 col-right center">
            <h3 class="h3 fontcolor-mainblue">{{ fill }}</h3>
          </div>
        </div>
      </div>
      <div class="trennlinie-light"></div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: ['title', 'fill']
}
</script>
